* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  min-width: 0;
}

label {
  line-height: 1;
  vertical-align: middle;
}

/* -------------------------------------------------------------------------- */
/* Score formatting in RadarChart */

tspan {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/* -------------------------------------------------------------------------- */
/* Questionnaire Checkboxes */

:root {
  --checkbox-color: #2196f3;
}

input[type="checkbox"] {
  line-height: 1;
}

input[type="checkbox"] {
  vertical-align: middle;
  height: 13px;
  width: 13px;
  position: relative;
  -webkit-appearance: none;
  margin-right: 8px;
}

input[type="checkbox"]:before {
  content: "";
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: 1px solid #767676;
  background-color: #fff;
}

input[type="checkbox"]:hover::before {
  border: 1px solid #4f4f4f;
}

input[type="checkbox"]:checked:hover::before {
  filter: brightness(90%);
}

input[type="checkbox"]:checked:disabled:hover::before {
  filter: none;
}

.checkbox-blue input[type="checkbox"]:checked:before {
  content: "";
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: 1px solid #767676;
  border: 1px solid var(--checkbox-color);
  background-color: var(--checkbox-color);
}

input[type="checkbox"]:checked:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 2px;
  box-sizing: border-box;
  height: 5px;
  width: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: translateY(-1.5px) rotate(-45deg);
  transform: translateY(-1.5px) rotate(-45deg);
}

input[type="checkbox"]:disabled::before {
  border: 1px solid #c9ced1;
  border-radius: 2px;
  background-color: #f0f4f8;
}

input[type="checkbox"]:checked:disabled::before {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background-color: #d1d1d1;
}

/* -------------------------------------------------------------------------- */
